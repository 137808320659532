<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>PAR GOL</li>
            </ul>
            <h2>Programma “Garanzia di Occupabilità dei Lavoratori — GOL”</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">
    <div class="courses-details-image">
        <img src="assets/img/pargol.jpg" style="max-height:496px; width:100%; object-fit: cover;  object-position: bottom bottom;" alt="image">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                    <ngx-tabset>
                        <ngx-tab tabTitle="PAR GOL">
                            <div class="courses-overview">
                                <h3>CHE COS’É IL PROGRAMMA GOL</h3>
                                <p>
                                    Il Programma nazionale “Garanzia Occupabilità Lavoratori” (2021-2025) è il perno dell’azione di riforma delle politiche attive del lavoro previsto nell’ambito del Piano Nazionale di Ripresa e Resilienza, Missione 5 “Inclusione e coesione”, Componente 1 “Politiche attive del lavoro e sostegno all’occupazione”, Riforma 1.1 “Politiche attive del lavoro e formazione” finanziato dall’Unione europea – Next Generation EU. Attuato dalle singole Regioni, sulla base dei Piani regionali (Par) approvati da Anpal, il Programma Gol tiene conto di un’analisi precisa e puntuale del contesto nel quale opera.
                                </p>
                                <p>
                                    L’andamento del mercato del lavoro territoriale tiene conto di fattori demografici, di struttura e dinamiche del mercato del lavoro, della popolazione in cerca di lavoro e popolazione non attiva, e degli interventi di sostegno al reddito. Gol ridisegna i servizi per il lavoro per migliorare l’inserimento lavorativo delle persone e prevede percorsi di accompagnamento al lavoro, di aggiornamento o riqualificazione professionale, e percorsi in rete con gli altri servizi territoriali (sociali, socio-sanitari, di conciliazione, educativi) nel caso di bisogni complessi, quali quelli di persone con disabilità o con fragilità. Grazie a un orientamento di base più mirato, attraverso un’attenta valutazione dell’occupabilità (assessment) la persona è indirizzata al percorso più adeguato.
                                </p>

                                <h3>A CHI SI RIVOLGE IL PROGRAMMA GOL
                                </h3>
                                <p>
                                    Sono Beneficiari del programma le persone dai 30 fino a 65 anni, residenti e/o domiciliate in Campania, che sono, alternativamente in almeno una delle seguenti situazioni:
                                </p>
                                <ul>
                                    <li>Beneficiari di ammortizzatori sociali in costanza di rapporto di lavoro: le specifiche categorie di lavoratori sono individuate dalla Legge di Bilancio 2022;</li>
                                    <li>Beneficiari di ammortizzatori sociali in assenza di rapporto di lavoro: disoccupati percettori di NASPI o DIS-COLL;</li>
                                    <li>Beneficiari di sostegno al reddito di natura assistenziale: percettori del Reddito di cittadinanza;</li>
                                    <li>Lavoratori fragili o vulnerabili: donne in condizioni di svantaggio, persone con disabilità, lavoratori maturi (55 anni e oltre), persone che sono in carico o sono segnalate dagli enti che operano nel sistema dei servizi sociali o sociosanitari e/o sono inserite in progetti/interventi di inclusione sociale;</li>
                                    <li>Disoccupati senza sostegno al reddito: disoccupati da almeno sei mesi, altri lavoratori con minori opportunità occupazionali (giovani e donne, anche non in condizioni di fragilità), lavoratori autonomi che cessano l’attività o con redditi molto bassi;</li>
                                    <li>Lavoratori con redditi molto bassi (i cosiddetti working poor): il cui reddito da lavoro dipendente o autonomo sia inferiore alla soglia dell’incapienza secondo la disciplina fiscale.</li>
                                </ul>

                                <h3>COME ISCRIVERSI AL PROGRAMMA GOL</h3>
                                <p>
                                    L’accesso al programma dei beneficiari avviene a seguito di convocazione e/o invito all’adesione da parte degli operatori dei Centri per l’Impiego della Campania. Gli operatori dei Centri per l’Impiego della Campania convocano mediante sms/mail i cittadini che hanno attiva una Dichiarazione di Immediata Disponibilità e quelli che la presentano dopo la pubblicazione del presente Avviso, nonché i cittadini che hanno presentato domanda per il Reddito di Cittadinanza, dando priorità alle donne, ai disoccupati di lunga durata, alle persone con disabilità, ai lavoratori over 55.                                 </p>
                                <p>
                                    Gli operatori dei Centri per l’Impiego convocano, altresì, i Beneficiari di ammortizzatori sociali in costanza di rapporto di lavoro, ma potenzialmente in transizione per effetto di crisi aziendali, con particolare riferimento alle attività previste nell’ambito del Percorso 5 – “Ricollocazione collettiva”. I beneficiari convocati/invitati dagli operatori dei Centri per l’Impiego della Campania sono tenuti a riscontrare la convocazione/invito ricevuto secondo quanto ivi descritto, compreso l’eventuale accesso tramite SPID/CIE – per il tramite del portale SILF Campania (https://lavoro.regione.campania.it/) – agli strumenti digitali predisposti da Regione Campania per il pre-assessment e l’indicazione della propria preferenza circa lo svolgimento (in presenza o da remoto) degli appuntamenti successivi. I beneficiari che optano per lo svolgimento in presenza degli appuntamenti successivi vengono convocati dagli operatori dei Centri per l’Impiego della Campania mediante sms/mail indicanti luogo, data ed ora dell’appuntamento. I beneficiari che optano per lo svolgimento da remoto degli appuntamenti successivi vengono convocati dagli operatori dei Centri per l’Impiego della Campania convocano mediante mail indicante data, ora e modalità di svolgimento del colloquio (VOIP o VIDEOCALL).                                </p>
                            </div>
                            <div class="btn-box mt-5">
                                <a routerLink="/contatti" class="default-btn"><i class="flaticon-tag"></i>Richiedi informazioni<span></span></a>
                            </div>
                            <div class="btn-box mt-5">
                                <a href="assets/catalogo-corsi.pdf" target="_blank" class="default-btn"><i class="flaticon-tag"></i>Scarica l'elenco corsi<span></span></a>
                                <a href="assets/elenco-aziende.pdf" target="_blank" class="default-btn ms-3"><i class="flaticon-tag"></i>Scarica l'elenco aziende<span></span></a>
                            </div>
                        </ngx-tab>
                        <ngx-tab tabTitle="Elenco dei Corsi PAR-GOL">
                            <div class="courses-overview">
                                <h3>SEP 01 - Agricoltura, silvicoltura e pesca</h3>
                                <a href="/dettaglio-corso/manutentore-del-verde">Manutentore del verde</a>
                            </div>
                            <br><br>
                            <div class="courses-overview">
                                <h3>SEP 10 - Meccanica, produzione e manutenzione di macchine, impiantistica</h3>
                                <a href="dettaglio-corso/operatore-alla-tornitura">Operatore alla tornitura</a>
                            </div>
                            <br><br>
                            <div class="courses-overview">
                                <h3>SEP 19 - Servizi socio-sanitari</h3>
                                <a href="dettaglio-corso/osa">Operatore Socio Assistenziale (OSA)</a><br>
                                <a href="dettaglio-corso/opi">Operatore dell’Infanzia (OPI)</a><br>
                                <a href="dettaglio-corso/animatore-sociale">Animatore Sociale</a><br>
                                <a href="dettaglio-corso/asacom">Assistente all’autonomia e alla comunicazione delle persone con disabilità</a>
                            </div>
                            <br><br>
                            <div class="courses-overview">
                                <h3>SEP 23 - Servizi turistici</h3>
                                <a href="dettaglio-corso/corso-operatore-servizio-bar">Operatore del servizio bar</a><br>
                                <a href="dettaglio-corso/corso-operatore-del-servizio-di-sala-cameriere">Operatore del servizio di sala – Cameriere</a>
                            </div>
                            <br><br>
                            <div class="courses-overview">
                                <h3>SEP 24 - Area comune</h3>
                                <a href="dettaglio-corso/operatore-segretariale">Operatore segretariale</a><br>
                                <a href="dettaglio-corso/operatore-amministrativo-contabile">Operatore Amministrativo Contabile</a><br>
                                <a href="dettaglio-corso/segretario-coordinatore-amministrativo">Segretario Coordinatore Amministrativo</a><br>
                                <a href="dettaglio-corso/tecnico-esperto-e-commerce">Tecnico esperto e-commerce</a><br>
                                <a href="dettaglio-corso/social-media-manager">Social Media Manager</a>
                            </div>
                        </ngx-tab>
                    </ngx-tabset>
                </div>
            </div>

        </div>
    </div>
</div>

